<template>
  <ui-card
    padding="custom"
    class="flex justify-between items-center gap-2.5 p-5"
    :border-color="isActive ? cardConfig.borderColor : 'white'"
  >
    <div class="grid gap-2.5">
      <ui-typography
        tag="h3"
        variant="h3"
        :class="cardConfig.textClass"
        :text="item.title"
      />
      <ui-typography
        variant="caption"
        class="relative before:rounded-full before:bg-black before:w-0.5 before:h-0.5 flex items-center gap-0.5"
      >
        {{ $t('Save') }} {{ item.percentOff }}%
      </ui-typography>
    </div>
    <div class="grid text-center">
      <ui-typography
        class="line-through"
        :class="cardConfig.priceClass"
      >
        {{ $n(item.price, 'currency', 'en') }}
      </ui-typography>
      <ui-typography
        :class="cardConfig.textClass"
        variant="h3"
        tag="h3"
      >
        {{ $n(item.priceWithOff, 'currency', 'en') }}
      </ui-typography>
    </div>
  </ui-card>
</template>

<script setup lang="ts">
  import { DurationSubscriptionType } from '~/globals/subscriptions';

  type Props = {
    item: DurationPlan;
    isActive?: boolean;
  };
  const props = defineProps<Props>();
  const cardConfig = computed(() => {
    return {
      [DurationSubscriptionType['1Month']]: {
        textClass: 'text-primary',
        priceClass: 'decoration-secondary',
        borderColor: 'secondary'
      },
      [DurationSubscriptionType['3Month']]: {
        textClass: 'text-primary',
        priceClass: 'decoration-primary',
        borderColor: 'primary'
      },
      [DurationSubscriptionType['12Month']]: {
        textClass: 'text-warning',
        priceClass: 'decoration-warning',
        borderColor: 'warning'
      }
    }[props.item.type];
  });
</script>

<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
    title="Share video"
  >
    <form
      class="flex flex-col gap-2.5"
      @submit="onSubmit"
    >
      <ui-fields-text
        name="link"
        label="Video link"
        required
      />
      <div class="text-center md:mt-5 mt-3">
        <ui-button
          text="Save"
          type="submit"
        />
      </div>
    </form>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  import { object } from 'yup';

  const emit = defineEmits(['update:modelValue']);
  const { onClose } = createModalState(emit);
  const validationFields = useValidationRules();

  const { handleSubmit } = useForm({
    initialValues: {
      link: ''
    },
    validationSchema: object({
      link: validationFields.url(validationFields.requiredString('Link'))
    })
  });
  const meetStore = useMeetStore();
  const onSubmit = handleSubmit(({ link }) => {
    meetStore.sendShareVideoCommand({
      link,
      status: VideoShareStatus.Play,
      muted: false,
      time: 0,
      volume: 1
    });
    onClose();
  });
</script>

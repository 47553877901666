<template>
  <ui-fields-control
    v-bind="props"
    type="radio"
    keep-value-on-unmount
    control-wrap-class="w-full"
    hide-error
  >
    <template #control="{ inputId, checked }">
      <label
        :for="inputId"
        :aria-disabled="disabled"
        :data-ui="checked && 'checked'"
      >
        <common-subscription-plan-card
          :item="item"
          :is-active="checked"
        />
      </label>
    </template>
  </ui-fields-control>
</template>

<script setup lang="ts">
  type Props = Omit<ControlField, 'keepValueOnUnmount'> & {
    item: DurationPlan;
  };
  const props = defineProps<Props>();
</script>

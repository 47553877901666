<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
  >
    <ui-typography
      variant="h3"
      class="mb-5 text-center"
      text="Administrator Settings"
    />
    <common-forms-admin-permissions
      :user="user"
      :room-id="roomId"
      @success="onSuccess"
      @close="onClose"
    >
      <template
        v-if="isAdmin"
        #after
      >
        <div
          v-for="(action, i) in actions"
          :key="i"
          class="pt-5"
        >
          <ui-button
            variant="error-link"
            hide-space
            size="md"
            :text="action.text"
            @click="action.onClick"
          />
        </div>
      </template>
    </common-forms-admin-permissions>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    roomId: ResourceId;
    user: RoomUser;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'delete', 'removeFromAdmin', 'success']);
  const { onSuccess, onClose } = createModalState(emit);
  const isAdmin = computed(() => props.user.user_status?.status === RoomUserStatus.Admin);

  const actions = computed(() => {
    return [
      {
        text: 'Delete From Room',
        onClick: () => emit('delete')
      },
      {
        text: 'Remove from Admin',
        onClick: () => emit('removeFromAdmin')
      }
    ];
  });
</script>

<template>
  <form @submit="onSubmit">
    <div class="grid grid-cols-1 sm:gap-5 gap-5 mb-12 divide-y divide-card-placeholder-border">
      <common-room-user-base-card
        :user="user"
        :status="userRoomStatus"
      />
      <div class="grid grid-cols-1 gap-5 pt-5">
        <div
          v-for="field in permissionFields"
          :key="field.value"
          class="flex items-center gap-2.5 justify-between"
        >
          <ui-typography
            :text="field.label"
            variant="h4"
            :for="field.value"
            tag="label"
          />
          <ui-fields-switch
            :checked-value="field.value"
            :input-id="field.value"
            name="permissions"
            class="ml-auto self-start"
          />
        </div>
        <ui-typography
          v-if="errors.permissions"
          variant="caption"
          class="text-error"
        >
          {{ errors.permissions }}
        </ui-typography>
      </div>
      <slot name="after" />
    </div>

    <div class="flex gap-3">
      <ui-button
        full
        text="Cancel"
        variant="secondary-outline"
        @click="$emit('close')"
      />
      <ui-button
        :text="!isAdmin ? 'Set as Admin' : 'Save'"
        type="submit"
        :loading="isSubmitting"
        full
        class="max-w-md mx-auto"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { useForm } from 'vee-validate';
  import { array } from 'yup';
  import { RoomAdminPermission } from '~/globals/rooms';

  type Props = {
    roomId: ResourceId;
    user: RoomUser;
  };

  const props = defineProps<Props>();
  const emit = defineEmits(['success', 'close']);
  const { t } = useI18n();
  const apiRoutes = useApiRoutes();
  const permissionFields = [
    {
      value: RoomAdminPermission.UpdateRoomDetails,
      label: 'Update Room details'
    },
    { value: RoomAdminPermission.DeleteMembers, label: 'Deleting users' },
    {
      value: RoomAdminPermission.ApproveRequests,
      label: 'Accepting new users'
    },
    { value: RoomAdminPermission.RejectRequests, label: 'Rejecting new users' },
    { value: RoomAdminPermission.DeleteMessages, label: 'Remove messages' }
    // { value: 'delete_room', label: 'Deleting a room' }
  ];

  const userStatus = computed(() => props.user.user_status);
  const userRoomStatus = computed(() => userStatus.value?.status);
  const isAdmin = computed(() => userRoomStatus.value === RoomUserStatus.Admin);

  const initialValues = computed(() => {
    return {
      permissions: isAdmin.value ? userStatus.value?.permissions || [] : useMap(permissionFields, 'value')
    };
  });

  const { handleSubmit, isSubmitting, values, errors } = useForm({
    initialValues,
    validationSchema: {
      permissions: array().min(1, t(MessagesError.invalidSelectPermissions))
    }
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return apiRoutes.rooms.setUserPermissions(props.roomId, props.user.id, form.permissions, !isAdmin.value);
      },
      initialValues,
      {
        onSuccess() {
          const { permissions } = unref(values);
          emit('success', permissions);
        },
        successToast: true
      }
    )
  );
</script>

<template>
  <form
    class="grid grid-cols-1 lg:gap-7 gap-5"
    v-bind="$attrs"
    @submit="onSubmit"
  >
    <div class="grid lg:gap-5 gap-2.5">
      <ui-fields-subscription-plan
        v-for="plan in durationPlans"
        :id="plan.type"
        :key="plan.type"
        :item="plan"
        :checked-value="plan.type"
        name="duration_plan"
      />
    </div>
    <ui-fields-control
      label="Top up my balance automatically after the expiration date"
      name="is_automatically"
      :checked-value="true"
      :unchecked-value="false"
    />
    <div class="flex">
      <ui-button
        text="Upgrade"
        type="submit"
        full
        class="max-w-md mx-auto"
        :loading="isSubmitting"
        :disabled="!meta.valid"
      />
    </div>
  </form>
</template>
<script setup lang="ts">
  import { object } from 'yup';
  import { DurationSubscriptionType } from '~/globals/subscriptions';

  const durationPlans = [
    {
      type: DurationSubscriptionType['12Month'],
      title: '12 Months',
      percentOff: 50,
      price: 240,
      priceWithOff: 120
    },
    {
      type: DurationSubscriptionType['3Month'],
      title: '3 Months',
      percentOff: 20,
      price: 60,
      priceWithOff: 50
    },
    {
      type: DurationSubscriptionType['1Month'],
      title: '1 Months',
      percentOff: 10,
      price: 20,
      priceWithOff: 18
    }
  ];

  const initialValues = computed(() => {
    return {
      duration_plan: null,
      is_automatically: true
    };
  });

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting, meta } = useForm({
    validationSchema: object({
      duration_plan: validationFields.requiredString()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      () => {
        //   TODO: added request
      },
      initialValues,
      {
        async onSuccess() {},
        isCleanData: true,
        successToast: true
      }
    )
  );
</script>

<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
  >
    <ui-typography
      variant="h3"
      class="mb-5 text-center"
      text="Create a New Tag"
    />
    <common-forms-create-interest
      v-bind="$props"
      @success="onSuccess"
    />
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  import type { TInterestableType } from '~/globals/interest';

  const emit = defineEmits(['update:modelValue', 'success']);
  type Props = {
    relatedId: number;
    interestableType: TInterestableType;
    interestableId: number;
  };
  defineProps<Props>();
  const { onSuccess } = createModalState(emit);
</script>

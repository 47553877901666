<template>
  <form
    class="grid gap-0 lg:gap-5 mb-7 max-w-md w-full mx-auto"
    @submit="onSubmit"
  >
    <ui-fields-text
      name="name"
      is-required
      type="text"
      label="Enter Your Tag Name"
    />

    <ui-button
      full
      type="submit"
      text="Create"
      :loading="isSubmitting"
    />
  </form>
</template>

<script setup lang="ts">
  import { object } from 'yup';
  import { useForm } from 'vee-validate';
  import type { TInterestableType } from '~/globals/interest';

  type Props = {
    relatedId: number;
    interestableType: TInterestableType;
    interestableId: number;
  };
  type Emits = {
    (e: 'success', value: Interest): boolean;
  };
  const props = defineProps<Props>();
  const emit = defineEmits<Emits>();
  const apiRoutes = useApiRoutes();
  const limitToasts = useLimitToasts();

  const initialValues = {
    name: ''
  };

  const validationFields = useValidationRules();

  const { handleSubmit, isSubmitting } = useForm({
    validationSchema: object({
      name: validationFields.requiredString()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      val => {
        const formData = {
          ...val,
          related_id: props.relatedId,
          interestable_type: props.interestableType,
          interestable_id: props.interestableId
        };

        return apiRoutes.interests.create(formData);
      },
      initialValues,
      {
        isShowToastError: false,
        onSuccess(data: Interest) {
          emit('success', data);
        },
        onError: limitToasts
      }
    )
  );
</script>
